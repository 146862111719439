<template>
	<main v-if="horse && horse.horse_id">
		<HeaderTab 
            :title="formattedNom" 
            :horse="formattedHorse" 
            :presence="presence"
            :horseIntraLocation="horseIntraLocation"
            :swiper_tabs="swiper_tabs" 
            :horse_readonly="read_only"
            :show_tag="true"
        />
		<div id="content">
			<div class="container-fluid">
				<div class="row">
					<div class="col-12">
						<div>
							<router-view :horse="horse"></router-view>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>

	<main v-else>
		<div id="content">
			<div class="container-fluid">
				<div class="row">
					<div class="col-12">
						<div class="box" v-if="horse">
							Chargement...
						</div>
						<div class="box" v-else>
							<HorseNotFound />
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>


<script type="text/javascript">
	import HorseMixin from "@/mixins/Horse.js";
	import ActesMixin from "@/mixins/Actes.js";
	import DocumentsMixin from "@/mixins/Documents.js";
	import GynecologieMixin from "@/mixins/Gynecologie.js";
	import Navigation from "@/mixins/Navigation.js";
	import _isEmpty from 'lodash/isEmpty'
	import Notes from "@/mixins/Notes.js"
	import Common from '@/assets/js/common.js'
	import IntraLocation from '@/mixins/IntraLocation.js'

	export default {
		name: "HorseFiche",
		mixins: [
            HorseMixin, 
            ActesMixin, 
            DocumentsMixin, 
			GynecologieMixin,
            Navigation,
			Notes,
			IntraLocation
        ],
		data () {
			return {
				actual_tab: 'HorseFicheInfo',
				has_notes: false,
				horse_id: 0,
				horse: {},
				presence: null,
				horseIntraLocation: null,
				main_vue: true,
				read_only: false,
				pere: null,
				mere: null,
				previous_status: null,
				actual_status: null,
				actual_season: null,
				previous_season: null,

				events_tab: {
					'displayMainVue': this.changeVue,
					'HorseFiche::refreshHorse': this.loadHorse,
					'HorseFiche::refreshHorsePlace': this.loadHorsePlace,
					'HorseFiche::refreshHorseStatus': this.loadHorseStatus
				}
			}
		},
		mounted() {
			this.horse_id = this.$route.params.horse_id
			this.horse_id = this.$sync.replaceWithReplicated('horse', this.horse_id)
			this.init_component()
		},
		beforeDestroy() {
            this.$store.commit({ type: 'updatehorseFicheReadonly', val: false })
		},
		methods: {
			async init_component() {
				// if(this.$route.name == 'horseFiche')
				// 	this.$router.replace({ name: this.actual_tab })

				
				await Promise.all([
					this.loadHorse(),
					this.loadHorsePlace(),
					this.loadHorseLocInterne()
				])
				
				this.hasNotes()
				await this.loadHorseStatus()
			},
			async loadHorse() {
				// On vérifie que le cheval est dans la whitelist par rapport à la limite imposée par le niveau de gamme
				const horses_whitelist = await this.getUserAuthorizedHorses()
				const horse_limit = this.getUserHorseLimit()
				const horse_id = parseInt(this.horse_id)

				// si mon cheval vient d'être ajouté (id négatif) il n'est pas encore dans les authorized horses
				if(horses_whitelist.indexOf(horse_id) === -1 || (horse_id < 0 && horses_whitelist.length + 1 > horse_limit)) {
					this.read_only = true
				}
				else {
					this.read_only = false
				}

                this.$store.commit({ type: 'updatehorseFicheReadonly', val: this.read_only })

				// On charge les infos du cheval (en cache cond)
				return this.getHorseById(horse_id)
				.then(result => {
					this.horse = result
				})
				.then(async () => {
					const promises = [
						this.getPedigree(1, null).then(res => this.pere = res),
						this.getPedigree(null, 1).then(res => this.mere = res),
					]

					return Promise.all(promises)
				})
				.catch(e => {
					console.error(e)
					this.horse = null
				})
			},
			async loadHorsePlace() {
				this.getHorseActualPlace(this.horse_id)
					.then(result => {
						this.presence = result
					})
					.catch(() => {
						this.presence = false
					})
			},
			async loadHorseLocInterne() {
				this.getHorseIntraLocation(this.horse_id)
					.then(result => {
						this.horseIntraLocation = result
					})
					.catch(() => {
						this.horseIntraLocation = false
					})
			},
			async loadHorseStatus() {
				if(!this.hasBreedingAccess && !this.horse) {
					return
				}

				this.getPreviousStatus().then(res => this.previous_status = res)
				this.getActualStatus().then(res => this.actual_status = res)
			},
			async getPedigree(pere = null, mere = null) {
				let horse_pedigree = await this.getHorsePedigree(this.horse.horse_id)
				if(horse_pedigree.pedigree_sireencode_1 && pere) {
					pere = await this.getHorseBySireEncode(horse_pedigree.pedigree_sireencode_1)
					return pere
				}
				else if(horse_pedigree.pedigree_sireencode_2 && mere) {
					mere = await this.getHorseBySireEncode(horse_pedigree.pedigree_sireencode_2)
					return mere
				}
				else {
					return null
				}
			},
			async getActualStatus() {
				this.actual_season = await this.getActualSeason()
				if(!this.actual_season) return null

				let actual_season_status = []
				await Common.asyncForEach(this.actual_season, async season => {
					actual_season_status.push(await this.loadSeasonMareStallionStatus(season))
				})

				return actual_season_status
			},
			async getPreviousStatus() {
				this.previous_season = await this.getPreviousSeason()
				if(!this.previous_season) return null

				let previous_season_status = await this.loadSeasonMareStallionStatus(this.previous_season)

				return previous_season_status ? previous_season_status : null
			},
			async loadSeasonMareStallionStatus(season) {
				let seasonMareHorse = await this.loadSeasonMareStallionBySeasonHorse(this.horse.horse_id, season.season_id)

				if(seasonMareHorse.length === 0) return null

				let seasonMare = {}
                let seasonMareStallion = {}
                if(seasonMareHorse && season) {
                    for(let i = 0; i < seasonMareHorse.length; i++) {
                        if(season.season_id == seasonMareHorse[i].season.season_id) {
                            seasonMare = seasonMareHorse[i]
                            let seasonmarestallion = seasonMare.seasonmarestallion
                            if(seasonmarestallion) {
                                for(let y = 0; y < seasonmarestallion.length; y++) {
                                	// on prend l'état physio de la jument si couple ou si trouple avec jument en porteuse
                                    if(seasonmarestallion[y].seasonmarestallion_default && ((seasonmarestallion[y].mare.horse.horse_id == this.horse.horse_id && seasonmarestallion[y].porteuse == null) || (seasonmarestallion[y].porteuse && seasonmarestallion[y].porteuse.horse.horse_id == this.horse.horse_id))) {
                                        seasonMareStallion = seasonmarestallion[y]
                                        continue;
                                    }
                                }
                            }
                        }
                    }
				}
				if (seasonMareStallion) {
					if (seasonMareStallion.porteuse) {
						return {
							"status": seasonMareStallion.seasonmarestallion_status,
							"horse_nom": seasonMareStallion.horse.horse_nom,
							"horse_id": seasonMareStallion.horse.horse_id,
							"donneuse_nom": seasonMareStallion.mare.horse.horse_nom,
							"donneuse_id": seasonMareStallion.mare.horse.horse_id,
						}
	
					} 
					if (seasonMareStallion.horse) {
						return {
							"status": seasonMareStallion.seasonmarestallion_status,
							"horse_nom": seasonMareStallion.horse.horse_nom,
							"horse_id": seasonMareStallion.horse.horse_id,
						}
					}
				}
				return null
			},
			async hasNotes(){
                const notes = await this.getHorseNotes(this.horse_id)
                this.has_notes = notes.length > 0
            },
            updateActualTab(href) {
                this.actual_tab = href
                if(this.$route.name == 'horseFiche')
                    this.$router.replace({ name: this.actual_tab })
            }
		},
		computed: {
			swiper_tabs() {
				if(_isEmpty(this.horse))
					return []

				// récupération des configs
				const config = this.getConfig('tabs_config')

				// récupération de la config correspondant à la fiche (mare, stallion ou autre) + user ou par défaut
				let type = 'horse'
				if(this.horse.horse_sexe == "F") {
					type = 'mare'
				}
				else if(this.horse.horse_stallion == 1) {
					type = 'stallion'
				}
		        const config_tabs = config[type][1].length > 0 ? config[type][1][0].tabs : config[type][0][0].tabs

				let tabs = [{
						label: 'global.planning',
						href : 'HorseFichePlanning',
						active: false
					}, {
						label: 'global.informations',
						href : 'HorseFicheInfo',
						active: false
					}, {
						label: 'global.mouvements',
						href : 'HorseFicheMouvements',
						active: false,
						dropdown: [{
								label: 'global.mouvements',
								href : 'HorseFicheMouvements',
								hidden: false,
								active: false,
							},
							{
								label: 'intra_location.deplacements_internes',
								href : 'HorseFicheDeplacementInterne',
								hidden: false,
								active: false,
							}]
					},{
						label: 'global.all_actes',
						href : 'HorseFicheAllActes',
						active: false
					}, {
						label: 'global.historique_actes',
						href : 'HorseFicheActes',
						active: false
					},{
						label: this.has_notes ? 'global.with_notes' : 'global.notes',
						href : 'HorseFicheNotes',
                        hidden: !this.hasNoteAccess,
						active: false
					}, {
						label: 'global.ordonnances',
						href : 'HorseFicheOrdonnances',
						active: false
					}, {
						label: 'tiers.tiers',
						href : 'HorseTiers',
						hidden: (!this.hasFacturationAccess && !this.hasEntitiesAccess && !this.hasBreedingAccess),
						active: false,
						dropdown: [{
								label: 'horse.owners',
								href : 'HorseTiers',
								hidden: false,
								active: false,
							},
							{
								label: 'horse.tiers_secondaires',
								href : 'HorseTiersSecondaires',
								hidden: false,
								active: false,
							}]
					}, {
						label: 'contact.contacts',
						href : 'HorseContact',
						active: false
					}, {
						label: 'horse.pension',
						href : 'HorsePension',
						hidden: !this.hasFacturationAccess,
						active: false
					}, {
						label: 'horse.factures',
						href : 'HorseFicheInvoice',
						hidden: !this.hasFacturationAccess,
						active: false
					}, {
						label: 'gynecologie.gynecologie',
						href : 'HorseGynecologie',
						hidden: !this.hasGynecologieAccess,
						active: false
					},  {
						label: 'global.contracts',
						href : 'ContractMareList',
						hidden: !this.hasSaillieMareAccess,
                        active: false
					}, {
						label: 'gynecologie.monte',
						href : 'HorseMonte',
						hidden: !this.hasMonteAccess,
						active: false,
						dropdown: [{
								label: 'global.contracts',
								href : 'ContractStallionList',
								hidden: false,
								active: false,
							},
							{
								label: 'global.personnalisation_contract',
								href : 'ContractConfigStallionList',
								hidden: false,
								active: false,
							},{
								label: 'global.syndication',
								href : 'StallionSyndication',
								hidden: false,
								active: false,
	                        },{
								label: 'gynecologie.bilan_saison',
								href: 'HorseStatFertilite',
								hidden: false,
								active: false,
							},{
								label: 'monte.stock_semence_distant',
								href: 'StallionStockDistant',
								hidden: !this.hasSemenceAccess,
								active: false,
							},{
								label: 'monte.stock_semence_local',
								href: 'StallionStockLocal',
								hidden: !this.hasSemenceAccess,
								active: false,
							},{
								label: 'monte.stat_saillies',
								href: 'StatSaillieStallion',
								hidden: false,
								active: false,
							}],
					}, {
						label: 'qualification.qualifications',
						href : 'HorseQualificationFiche',
						hidden: !this.hasFacturationAccess,
						active: false
					}, {
                        id: 'sanitaire',
                        label: 'global.result_sanitaire',
                        active: false,
                        href : 'horseSanitaire',
						hidden: !this.hasConfTableAccess,
						dropdown: [{
								label: 'global.result_sanitaire',
								href : 'horseSanitaire',
								hidden: false,
								active: false,
							},
							{
								label: 'global.teletransmissionSire',
								href : 'horseTeletransmissionSire',
								hidden: !this.hasSanitaireAccess,
								active: false,
							}]
					}, {
						label: 'horse.production',
						href : 'HorseProduct',
						hidden: !this.hasGynecologieAccess,
						active: false
					},{
						label: 'croissance.titre',
						href : 'HorseSuiviCroissance',
						active: false
					},{
						label: 'charge.titre',
						href : 'HorseCharge',
						active: false
					},{
						label: 'pax.pax',
						href : 'HorsePax',
						hidden: !this.hasPaxAccess,
						active: false,
						dropdown: [{
							label: 'pax.caracterisation_pax',
							href : 'HorsePax',
							hidden: !this.hasPaxAccess,
							active: false,
						},
						{
							label: 'pax.dispo_pays',
							href : 'HorsePaxPays',
							hidden: !this.hasPaxStallionAccess,
							active: false,
						}]
					},
                    {
						label: 'temperature.suivi_temperature',
						href : 'HorseSuiviTemperature',
						active: false
					},
					{
                        id: 'courrier_perso',
                        label: 'courrier_type.title',
                        active: false,
                        href : 'HorseCourrierPerso',
                    },
					{
                        id: 'web_content',
                        label: 'global.web_content',
                        active: false,
                        href : 'HorseWebContent',
                    },
                    {
                    	id: 'charge_supplier',
                    	label: 'invoice.charge_supplier',
						hidden: !this.hasInvoiceSupplierOCR,
                    	active: false,
                    	href: 'HorseChargeSupplier'
                    },
                    {
                    	id: 'horse_congelation',
                    	label: 'monte.congelation.congelation',
						hidden: !this.hasCongelAccess,
                    	active: false,
                    	href: 'StallionCongelation',
                    	dropdown: [{
							label: 'monte.congelation.settings',
							href : 'StallionCongelation',
							hidden: !this.hasCongelAccess,
							active: false,
						}]
                    }
				]

				let res_tab = config_tabs.map(tab => tabs.find(t => t.href == tab))

				res_tab.push({
					label: '',
					icon: ['fal', 'cogs'],
					href : 'tabConfig',
					params: {type: type, from: this.$route.path},
					active: false
				})

				res_tab[0].active = true

                this.updateActualTab(res_tab[0].href)

				return res_tab
			},
			hasEntitiesAccess() {
				return this.$store.state.userAccess.hasEntitiesAccess
			},
			hasFacturationAccess() {
				return this.$store.state.userAccess.hasFacturationAccess
			},
			hasBreedingAccess() {
				return this.$store.state.userAccess.hasBreedingAccess
			},
			hasGynecologieAccess() {
				return (this.hasBreedingAccess && this.horse.horse_sexe == "F")
			},
			hasSaillieStallionAccess() {
				return (this.$store.state.userAccess.hasSaillieAccess && this.horse.horse_stallion == 1)
			},
			hasSaillieMareAccess() {
				return (this.$store.state.userAccess.hasSaillieAccess && this.horse.horse_sexe == "F")
			},
			hasMonteAccess() {
				return (this.hasBreedingAccess && this.horse.horse_stallion)
			},
			hasSanitaireAccess() {
                return this.$store.state.userAccess.hasSanitaireAccess
            },
			hasConfTableAccess() {
                return this.$store.state.userAccess.hasConfTableAccess
            },
            hasNoteAccess() {
                return this.$store.state.userAccess.hasNoteAccess
            },
			hasPaxAccess() {
				return this.$store.state.userAccess.hasPaxAccess
			},
			hasPaxStallionAccess() {
				return (this.hasPaxAccess && this.horse.horse_stallion == 1)
			},
			hasSemenceAccess() {
				return this.$store.state.userAccess.hasSemenceAccess
			},
            hasInvoiceSupplierOCR() {
                return this.$store.state.userAccess.hasInvoiceSupplierOCR
            },
            hasCongelAccess() {
            	return this.$store.state.userAccess.hasCongelAccess
            },
			paramsHorseId() {
				return this.$route.params.horse_id
			},
			formattedHorse(){
				this.$set(this.horse, 'pere', this.pere)
				this.$set(this.horse, 'mere', this.mere)
				this.$set(this.horse, 'actual_status', this.actual_status)
				this.$set(this.horse, 'actual_season', this.actual_season)
				this.$set(this.horse, 'previous_status', this.previous_status)
				this.$set(this.horse, 'previous_season', this.previous_season)

                return this.horse
            },
			formattedNom() {
				return this.horse.horse_nom+(this.horse.horse_alias ? ' '+this.$t('horse.form.alias').toLowerCase()+' '+this.horse.horse_alias : '')
			}
		},
		watch: {
			"paramsHorseId" (val, prev) {
				if(val == prev) return
				this.horse_id = val
				this.init_component()
			},
			'formattedNom'(val) {
				document.title = val
			}
		},
		components: {
			HorseNotFound: () => import('@/components/HorseNotFound'),
			HeaderTab: () => import('@/components/HeaderTab'),
		}
	}
</script>
